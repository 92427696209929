


























































import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { AgGridVue } from '@ag-grid-community/vue';
import { SelectionChangedEvent, GridReadyEvent } from '@ag-grid-community/core';
import DateRangeFilter from '@/components/date-range-filter.vue';
import Tooltip from '@/components/tooltip.vue';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import ContentDialog from '@/components/content-dialog.vue';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import ClientModel from '@/models/crm/client.model';
import AgGridWrapper from '@/components/ag-grid-wrapper.vue';
import { IGridConfig } from '@/interfaces/grid-config.interface';
import { GridHelper } from '@/utils/helpers/grid-helper';
import ClientManagementModel from '@/models/crm/client-management.model';
import ClientManagementService from '@/services/crm/client-management.service';
import { DateHelper } from '@/utils/helpers/date-helper';

type DataGridFilterConfig = {
  keyword: string | undefined;
};

@Component({
  components: {
    DataGridFilter,
    DateRangeFilter,
    Tooltip,
    AgGridWrapper,
    AgGridVue,
    ContentDialog,
  },
})
export default class CrmClientsManagement extends Vue {
  @inject(InjectionIdEnum.CrmClientManagementService)
  private clientManagementService!: ClientManagementService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @InjectReactive('activeClient') readonly activeClient!: ClientModel;

  grid: GridReadyEvent | null = null;

  gridSettings: IGridConfig = {
    loading: false,
    defaultSort: [{ colId: 'managementName', sort: 'desc' }],
    columnDefs: [
      GridHelper.getSelectionColDef(),
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.managementName')}`,
        field: 'managementName',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.representant')}`,
        field: 'representantName',
        autoHeight: true,
        flex: 1,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.situation')}`,
        field: 'situation',
        maxWidth: 135,
        flex: 0.3,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.reason')}`,
        field: 'reason',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.observation')}`,
        field: 'observation',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.justificationDate')}`,
        field: 'representantJustificationDate',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.justification')}`,
        field: 'representantJustification',
        autoHeight: true,
        flex: 0.5,
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.evaluationDate')}`,
        field: 'managerJustificationDate',
        maxWidth: 155,
        valueGetter: (params): string => GridHelper.valueGetter(params, DateHelper.formatToIsoDateTimeString),
        valueFormatter: (params): string => DateHelper.formatToLocale(params.value),
        cellClass: 'dateISO',
      },
      {
        headerName: `${this.$t('crm.view.clientsManagement.grid.evaluation')}`,
        field: 'managerJustification',
        autoHeight: true,
        flex: 0.5,
      },
    ],
  };

  filters: DataGridFilterConfig = {
    keyword: undefined,
  };

  items: ClientManagementModel[] = [];

  selected: ClientManagementModel[] = [];

  async mounted(): Promise<void> {
    this.loadItems();
  }

  onSelectionChanged(change: SelectionChangedEvent, selected: ClientManagementModel[]): void {
    this.selected = selected;
  }

  onExport(selected: ClientManagementModel[]): void {
    if (this.grid) {
      const onlySelected = !!selected.length && this.filteredItems.length !== selected.length;
      const columnKeys = this.gridSettings.columnDefs
        .filter((x) => !x.checkboxSelection)
        .map((x) => x.colId || x.field || '');

      this.grid.api.exportDataAsExcel({
        onlySelected,
        columnKeys,
        allColumns: true,
        author: 'IBtech',
        sheetName: 'Gestão de Clientes',
        fileName: ClientManagementService.generateClientsManagementExportFilename(new Date()),
      });
    }
  }

  get filteredItems(): ClientManagementModel[] {
    let filteredItems = this.items;

    if (this.filters.keyword) {
      const columnsToSearch = [
        'managementName',
        'situation',
        'reason',
        'representantName',
        'representantJustification',
        'managerJustification',
      ];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    return filteredItems;
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  private async loadItems(): Promise<void> {
    this.gridSettings.loading = true;

    this.items = await this.clientManagementService.getClientsManagement(this.getClientIdFromRoute());
    this.gridSettings.loading = false;
  }

  private getClientIdFromRoute(): string {
    if (!this.activeClient) {
      const currentRoute = this.routerService.route();
      return currentRoute.params && currentRoute.params.clientId;
    }
    return this.activeClient.cnpjCpf;
  }
}
